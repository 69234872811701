.header {
  position: relative;
  background-color: #293d5a;
  width: 100%;
  height: 175px;
  color: #fff;
  box-sizing: border-box; /* Add this line */

  @media screen and (max-width: 1024px) {
    padding: 50px; /* Increase the padding */
    height: 50px; /* Set a taller height */
  }

  @media screen and (max-width: 768px) {
    padding: 35px; /* Increase the padding */
    height: 35px; /* Set a taller height */
  }
}

.header-link {
  text-decoration: none;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header-left {
  display: flex;
  flex-direction: column;
  margin-left: 50px; /* Adjust the value as desired */
}

.header-title h1 {
  margin: 0;
  font-size: 24px;
}

.header-title h2 {
  margin: 0;
  font-size: 18px;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-books {
  display: flex;
  align-items: center;
}

.header-book {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
}

.book1 {
  background-image: url('../images/ManufacturingMurder_150x174.png');
}

.book2 {
  background-image: url('../images/ForgettingTomorrow_150x174.png');
}

.book3 {
  background-image: url('../images/Spark_150x174.png');
}

.header-title h1 {
  font-size: 64px; /* Update the font size as desired */
}

.header-title-spacing {
  margin-bottom: 16px; /* Add margin-bottom for the blank line */
}

.header-title h2 {
  font-size: 20px;
}

/* Hide images on mobile */
@media (max-width: 991px) {
  .header-book {
    display: none;
  }

  .header-title h1 {
    font-size: 32px;
  }

  .header-title h2 {
    font-size: 12px;
  }

  /* Center alignment for h1 and h2 on smaller devices */
  .header-left {
    text-align: center;
  }
}

@media (max-width: 768px) {
  /* Adjust font size for smaller devices */
  .header-title h1 {
    font-size: 24px;
  }

  .header-title h2 {
    font-size: 12px;
  }

  /* Center alignment for h1 and h2 on smaller devices */
  .header-left {
    text-align: left;
  }
}
