.about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px; /* Add padding on each side */
}

.about-container {
  width: 600px;
  text-align: left;
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
}

.category {
  margin-bottom: 30px;
}

.category-title {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.question {
  margin-bottom: 20px;
}

.special-note {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.special-note p {
  font-size: 18px;
  color: #000;
}

.question-title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.category-space {
  margin-bottom: 20px;
}

.question-answer {
  font-size: 16px;
  color: #000;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .about-container {
    width: auto; /* Allow the content to expand to full width */
    padding: 0 2px; /* Add padding on each side for smaller screens */
  }
}
