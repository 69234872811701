.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.hero-left {
  width: 65%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.hero-right {
  width: 35%;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 20px;
}

.facebook-frame {
  background-color: #d4a25b; /* Existing background color */
  padding: 10px;
  margin-bottom: 10px; /* Creates space below the button */
  text-align: center;
  border-radius: 5px;
  display: flex; /* Utilizes Flexbox for centering content */
  justify-content: center; /* Centers content horizontally */
}

.facebook-link {
  color: #292929;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #000; /* Text color for readability */
  border-radius: 4px; /* Rounded corners for the button */
  width: 100%; /* Adjusts width considering padding */
  text-align: center; /* Ensures text is centered */
}

.hero-title {
  font-size: 24px;
  color: #292929;
  margin-bottom: 10px;
}

.hero-img {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 10px;
}

.hero-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.hero-button {
  display: inline-block;
  background-color: #292929;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #555;
}

.giveaway-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.giveaway-img {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.giveaway-button {
  display: inline-block;
  background-color: #292929;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.giveaway-button:hover {
  background-color: #555;
}

.giveaway-books {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.giveaway-section {
  background-color: #ccc;
  width: 100%;
  color: black;
  border-radius: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .hero-left, .hero-right {
    width: 100%;
  }

  .hero-right {
    margin-left: 0;
    margin-top: 20px;
  }

  .hero-frame {
    width: 90%;
  }

  .hero-updates {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
