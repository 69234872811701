.series-updates {
  background-color: #f4f4f4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
}

.book-covers-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.book-cover {
  text-align: center;
  flex: 1 1 300px; /* Allows boxes to grow and shrink with a basis of 300px */
  margin: 0 10px;
}

.cover-img {
  width: 75%; /* Adjust size as necessary */
  height: auto;
}

.book-release-date {
  font-weight: bold;
  margin-bottom: 5px;
}

.book-long-description {
  font-size: 14px !important; /* Smaller font size for long descriptions */
  color: #661;
  margin-top: 10px;
  padding: 0 10px;
  text-align: justify;
  margin-bottom: 15px;
}

