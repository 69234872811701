.book-details-container {
  display: flex;
  justify-content: center;
}

.book-details {
  display: flex;
  align-items: flex-start;
  max-width: 1200px; /* Container width */
  margin: 40px;
  background-color: #f1f1f1; /* Background color for the whole container */
}

.book-cover {
  flex: 1; /* Each section takes equal space */
  max-width: 300px; /* Maximum width of the image */
  height: auto;
}

.book-details.two-cols {
  max-width: 800px; /* Smaller max width for two columns */
}

.book-details.two-cols .book-cover,
.book-details.three-cols .book-cover {
  flex-basis: 25%;
  max-width: 300px;
  height: auto;
}

.book-details.two-cols .book-description-container {
  flex-basis: 67%;
  text-align: left;
  margin-left: 40px;
}

.book-details.three-cols {
  max-width: 1200px; /* Larger max width for three columns */
}

.book-details.three-cols .book-description-container {
  flex-basis: 50%;
  text-align: left;
  margin-left: 40px;
}

.book-description-container {
  flex: 1; /* Equal spacing */
  margin-left: 40px;
  text-align: left;
}

.book-status-container {
  flex-basis: 25%;
  background-color: #e2e2e2; /* Light grey to stand out */
  padding: 20px; /* Padding for better spacing */
  margin-left: 40px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  color: #333; /* Dark font color for good contrast */
}

.book-title {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
}

.book-long-description {
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}

.book-buy-button {
  display: inline-block;
  background-color: #d91e48;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.status-heading {
  color: #333; /* Matches the text color for consistency */
  margin-bottom: 10px; /* Provides some spacing below the heading */
  font-size: 18px; /* Appropriate size for subheadings */
}

@media screen and (max-width: 768px) {
  .book-details {
    flex-direction: column;
    align-items: center;
    margin: 20px;
    text-align: center;
  }

  .book-cover,
  .book-description-container,
  .book-status-container {
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-top: 20px;
  }

  .book-status-container {
    background-color: #e2e2e2; /* Ensure background color remains on mobile */
    box-shadow: none; /* Remove shadow on mobile for flat design */
  }
}