.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f5f5f5;
}

.contact-title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.contact-email {
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.contact-email-link {
  color: #d91e48;
  text-decoration: underline;
  font-size: 16px;
}
