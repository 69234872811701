.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    color: black;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  a {
    color: #000; /* Changed the font color to black */
    text-decoration: underline;
  }
  
  @media screen and (max-width: 768px) {
    .privacy-policy-container {
      padding: 20px;
    }
  }
  