.update-text-container {
  background-color: #ede3d9;
  color: #333;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.4;
  text-align: left;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  margin: 10px 0;
  border: 1px solid #dcdcdc;
}

@media (max-width: 768px) {
  .update-text-container {
    padding: 8px;
    font-size: 14px;
    line-height: 1.3;
  }
}
