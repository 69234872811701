.menu-section {
  background-color: #293d5a;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.menu-item {
  display: inline-block;
  margin-right: 50px;
}

.menu-link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.social-icons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.social-icon {
  color: #fff;
  font-size: 24px;
  margin-left: 10px;
  transition: color 0.3s ease-in-out;
}

.social-icon:hover {
  color: #f8f8f8;
}

.menu-toggle {
  display: none;
}

@media screen and (max-width: 1024px) {
  .menu-section {
    padding: 10px;
    height: 20px;
  }

  .menu {
    display: none;
  }

  .menu-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999;
  }

  .menu-toggle-line {
    width: 25px;
    height: 2px;
    background-color: #fff;
    margin: 4px;
  }

  .menu-toggle-text {
    color: #fff;
    font-size: 16px;
  }

  .menu.open {
    display: flex;
    flex-direction: column;
    background-color: #293d5a;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }

  .menu-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .menu-social-icons {
    justify-content: center;
  }
}
