.books {
  padding: 40px;
  display: flex;
  justify-content: center;
  color: black;
}

.books-container {
  width: 100%;
  max-width: 1200px; /* Set a maximum width for the books container */
  display: flex;
  justify-content: space-between; /* Added to separate books and reading order */
  flex-wrap: wrap; /* Added to wrap books in multiple rows */
}

.my-books {
  flex: 1;
  margin-right: 20px;
}

.reading-order {
  flex: 0 0 300px;
  padding: 10px;
  background-color: #ccc;
  border-radius: 15px;
  margin-left: 20px; /* Add margin to the left side */
}

.series-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px; /* Increase the font size for series names */
}

.books-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.book {
  flex: 0 0 200px;
  margin: 10px;
  text-align: center;
}

.book-cover {
  width: 200px;
  height: auto;
  max-height: 400px;
}

.book-title {
  font-size: 16px !important; /* Decrease the font size for book titles */
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.book-description {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

.book-button {
  display: inline-block;
  background-color: #d91e48;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.book-button:hover {
  background-color: #c31a42;
}

.reading-order .reading-order-label {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.reading-order .reading-order-item {
  text-align: left;
  font-size: 16px;
}

/* Responsive Styles */

@media screen {
  .book-cover {
    width: 80%; /* Reduce the width of book covers by 30% */
    max-width: 200px; /* Limit the maximum width of book covers to maintain aspect ratio */
  }
}

@media screen and (max-width: 768px) {
  .books {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .book-title {
    font-size: 16px !important; /* Reset the font size for book titles on larger screens */
  }

  .series-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px; /* Increase the font size for series names */
  }

  .books-container {
    justify-content: center; /* Center books on smaller screens */
  }

  .my-books {
    margin-right: 0; /* Remove right margin on smaller screens */
  }

  .reading-order {
    flex: 0 0 100%; /* Full width for reading order on smaller screens */
    margin-top: 20px; /* Add top margin for separation */
  }

  @media screen and (max-width: 320px) {
    .book-cover {
      width: 125px; /* Reduce the width of book covers on smaller screens */
    }
  }
}
