/* Base styles for the normal screen layout */
.herald {
  font-family: 'Times New Roman', Times, serif;
  color: #333;
  background-color: #f2f2f2;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.herald-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #004d40;
  color: white;
}

.subscribe-button {
  text-decoration: none;
  background-color: white;
  color: #004d40;
  padding: 5px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ddd;
  }
}

.date-weather {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-weather,
.subscribe-button {
  margin: 0;
}

.weather {
  font-size: 1rem;
  color: #ddd;
  margin-top: 5px;
}

.herald-content {
  display: flex;
  flex-direction: row;
}

.herald-article {
  flex-basis: 75%;
  text-align: justify;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: -5px 0 5px -5px #333;
}

.header-section {
  flex: 1;
  display: flex;
}

.herald-sidebar {
  flex-basis: 25%;
  padding: 0 20px;
  border-right: 2px solid #d0e0e3;
}

.herald-sidebar p {
  font-size: 1rem;
  margin: 15px 0;
  cursor: pointer;
  background-color: #f8ecd5;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:nth-child(even) {
    background-color: #d5f8ec;
  }

  &:hover {
    color: blue;
    text-decoration: underline;
  }
}

.herald-article h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0;
}

.herald-article p {
  font-size: 1rem;
  line-height: 1.6;
}

.byline {
  font-style: italic;
  margin-bottom: 10px;
  color: #555;
}

/* Media query for screens with a maximum width of 768px (tablets and smaller screens) */
@media screen and (max-width: 768px) {
  .herald-content {
    flex-direction: column; /* Change to a column layout for smaller screens */
  }

  .herald-article,
  .herald-sidebar {
    flex-basis: auto; /* Remove fixed widths for smaller screens */
    margin-top: 0; /* Remove margin for smaller screens */
  }
}

/* Media query for screens with a maximum width of 768px (tablets and smaller screens) */
@media screen and (max-width: 768px) {
  .herald-header {
    flex-direction: column; /* Change to a column layout for smaller screens */
  }

  .header-section {
    text-align: center; /* Center-align header content on smaller screens */
    margin: 10px 0; /* Add some vertical spacing between header elements */
  }

  .subscribe-button {
    margin-top: 10px; /* Add space between subscribe button and other header elements */
  }
}