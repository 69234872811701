.footer {
    margin-top: 40px;
    text-align: center;
  }
  
  .footer-line {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px auto;
    width: 25%;
  }
  
  .footer-content {
    margin: 10px 0;
  }
  
  .privacy-policy-link {
    font-size: 12px;
    color: #666;
    text-decoration: none;
  }
  
  @media screen and (max-width: 768px) {
    .footer {
      margin-top: 20px;
    }
  }
  